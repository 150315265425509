import { Controller } from "@hotwired/stimulus"
import { toggleClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../../shared/utils/stimulus"

class RatesMatrixNew extends Controller {
  static targets = [`hideable`, `hideableAmount`, `toggle`]

  connect() {
    this.hideableTargets.forEach((target) => {
      target.classList.add(`--hidden`)
    })

    this.hideableAmountTargets.forEach((target) => {
      target.classList.add(`--hidden`)
    })
  }

  toggleTable(event) {
    const button = event.currentTarget
    const targetSection =
      button.closest(`[data-rates-matrix-new-target="table-0"]`) ||
      button.closest(`[data-rates-matrix-new-target^="table"]`)
    const hideableTargets = targetSection.querySelectorAll(
      `[data-rates-matrix-new-target="hideable"]`
    )
    const isHidden = hideableTargets[0].classList.contains(`--hidden`)

    hideableTargets.forEach((target) => {
      toggleClass(target, `--hidden`)
    })

    const hideableAmountTargets = targetSection.querySelectorAll(
      `[data-rates-matrix-new-target="hideableAmount"]`
    )
    if (!isHidden) {
      hideableAmountTargets.forEach((target) => {
        target.classList.add(`--hidden`)
      })

      const amountsButton = targetSection.querySelector(
        `.amounts-plus-icon, .amounts-minus-icon`
      )
      if (amountsButton) {
        const plusIcon = targetSection.querySelector(`.amounts-plus-icon`)
        const minusIcon = targetSection.querySelector(`.amounts-minus-icon`)
        plusIcon.classList.remove(`--hidden`)
        minusIcon.classList.add(`--hidden`)
      }
    }

    this.toggleIcons(button, !isHidden)

    const toggleText = button.querySelector(
      `[data-rates-matrix-new-target="toggle"]`
    )
    if (toggleText) {
      toggleText.innerHTML = isHidden ? `Visa mindre` : `Visa mer`
    }
  }

  toggleTableAmounts(event) {
    const button = event.currentTarget
    const targetSection =
      button.closest(`[data-rates-matrix-new-target="table-0"]`) ||
      button.closest(`[data-rates-matrix-new-target^="table"]`)
    const hideableAmountTargets = targetSection.querySelectorAll(
      `[data-rates-matrix-new-target="hideableAmount"]`
    )

    hideableAmountTargets.forEach((target) => {
      toggleClass(target, `--hidden`)
    })

    const isNowVisible =
      !hideableAmountTargets[0].classList.contains(`--hidden`)

    this.toggleIcons(button, isNowVisible)
  }

  toggleIcons(button, isNowVisible) {
    const plusIcon = button.querySelector(`.plus-icon`)
    const minusIcon = button.querySelector(`.minus-icon`)

    const amountsPlusIcon = button.querySelector(`.amounts-plus-icon`)
    const amountsMinusIcon = button.querySelector(`.amounts-minus-icon`)

    if (plusIcon && minusIcon) {
      plusIcon.classList.toggle(`--hidden`, !isNowVisible)
      minusIcon.classList.toggle(`--hidden`, isNowVisible)
    }

    if (amountsPlusIcon && amountsMinusIcon) {
      amountsPlusIcon.classList.toggle(`--hidden`, isNowVisible)
      amountsMinusIcon.classList.toggle(`--hidden`, !isNowVisible)
    }

    const buttonHint = button.querySelector(`.button-hint`)
    if (buttonHint) {
      buttonHint.classList.toggle(`--hidden`, !isNowVisible)
      buttonHint.classList.toggle(`show-hint`, isNowVisible)
    }
  }
}

Stimulus.register(`rates-matrix-new`, RatesMatrixNew)
